import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DBSchema, IDBPDatabase, openDB } from "idb";
import { useEffect, useState } from "react";

export interface Symptom {
  name: string;
  color: string;
  createdAt: Date;
}

export interface Occurrence {
  id?: string;
  symptomName: string;
  createdAt: Date;
  note?: string;
}

export interface Schema extends DBSchema {
  symptoms: {
    key: string;
    value: Symptom;
    indexes: {
      name: string;
      color: string;
      createdAt: Date;
    };
  };
  occurrences: {
    key: string;
    value: Occurrence;
    indexes: {
      createdAt: Date;
    };
  };
}

export type Database = IDBPDatabase<Schema>;

export const useDatabase = () => {
  const [database, setDatabase] = useState<Database>();

  useEffect(() => {
    openDB<Schema>("feels-bad.app.db", 4, {
      upgrade: (db) => {
        const symptomStore = db.createObjectStore("symptoms", {
          keyPath: "name",
          autoIncrement: false,
        });
        const occurrenceStore = db.createObjectStore("occurrences", {
          keyPath: "id",
          autoIncrement: true,
        });

        symptomStore.createIndex("name", "name", {
          unique: true,
        });
        symptomStore.createIndex("createdAt", "createdAt");
        occurrenceStore.createIndex("createdAt", "createdAt");
      },
    }).then(
      (db) => {
        setDatabase(db);
      },
      (err) => {
        // TODO(bengreenier): this is not sufficient, but it never fails so
        console.error(err);
        setDatabase(undefined);
      }
    );
  }, []);

  return database;
};

type QueryKeyWithDatabase = [string, { db?: Database }];

export const useSymptoms = (db?: Database) =>
  useQuery(
    ["symptoms", { db }] as QueryKeyWithDatabase,
    async ({ queryKey }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, { db }] = queryKey;

      if (!db) throw new Error("Database not ready");

      const data = await db.getAllFromIndex("symptoms", "createdAt");

      return data.map((d) => ({ ...d, color: d.color ?? "whiteAlpha.200" }));
    },
    {
      initialData: [],
    }
  );

export const useOccurrences = (db?: Database) =>
  useQuery(
    ["occurrences", { db }] as QueryKeyWithDatabase,
    async ({ queryKey }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, { db }] = queryKey;

      if (!db) throw new Error("Database not ready");

      const data = await db.getAllFromIndex("occurrences", "createdAt");

      return data.reverse();
    },
    {
      initialData: [],
    }
  );

export const useSymptomMutation = (db?: Database) => {
  const queryClient = useQueryClient();
  return useMutation<
    string,
    Error,
    Partial<Omit<Symptom, "createdAt">>,
    unknown
  >(
    (symptom: Partial<Omit<Symptom, "createdAt">>) => {
      if (!db) throw new Error("Database not ready");
      if (!symptom.name) throw new Error("A name must be specified");

      return db.add("symptoms", {
        name: symptom.name,
        color: symptom.color ?? "whiteAlpha.200",
        createdAt: new Date(),
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["symptoms"]);
      },
    }
  );
};

export const useOccurrenceAddMutation = (db?: Database) => {
  const queryClient = useQueryClient();
  return useMutation(
    (occurrence: Omit<Occurrence, "createdAt">) => {
      if (!db) throw new Error("Database not ready");

      return db.add("occurrences", {
        ...occurrence,
        createdAt: new Date(),
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["occurrences"]);
      },
    }
  );
};

export const useOccurrenceRemoveLastMutation = (db?: Database) => {
  const queryClient = useQueryClient();
  return useMutation(
    async () => {
      if (!db) throw new Error("Database not ready");

      const keys = await db.getAllKeysFromIndex("occurrences", "createdAt");

      return db.delete("occurrences", keys[keys.length - 1]);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["occurrences"]);
      },
    }
  );
};

export const useClearMutation = (db?: Database) => {
  const queryClient = useQueryClient();
  return useMutation(
    async () => {
      if (!db) throw new Error("Database not ready");

      await db.clear("symptoms");
      await db.clear("occurrences");
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["occurrences"]);
        await queryClient.invalidateQueries(["symptoms"]);
      },
    }
  );
};
