import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { USER_NOTE_SYMPTOM_ID } from "./constants";
import {
  Database,
  useClearMutation,
  useOccurrences,
  useSymptoms,
} from "./data";

interface Props {
  database: Database | undefined;
}

export const ReportClearButton = (props: Props) => {
  const { database } = props;

  const symptoms = useSymptoms(database);
  const occurrences = useOccurrences(database);
  const clearMutation = useClearMutation(database);

  return (
    <Button
      size={"sm"}
      mr={4}
      color="red.500"
      disabled={symptoms.data.length === 0 && occurrences.data.length === 0}
      onClick={() => {
        // TODO(bengreenier): This probably needs an "are you sure" modal
        clearMutation.mutate();
      }}
    >
      Erase All
    </Button>
  );
};

export const ReportDownloadButton = (props: Props) => {
  const { database } = props;
  const occurrences = useOccurrences(database);

  return (
    <Button
      size={"sm"}
      mr={4}
      onClick={() => {
        const data = [["symptomName", "timestamp", "note"]]
          .concat(
            occurrences.data.map((e) => [
              e.symptomName === USER_NOTE_SYMPTOM_ID
                ? "User Note"
                : e.symptomName,
              e.createdAt.toISOString(),
              e.note ?? "",
            ])
          )
          .map((e) => e.join(","))
          .join("\n");

        const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.setAttribute("href", url);
        link.setAttribute("download", "symptom_data.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }}
    >
      Export
    </Button>
  );
};

export const ReportView = (props: Props) => {
  const { database } = props;
  const occurrences = useOccurrences(database);

  return (
    <TableContainer>
      <Table size="sm" overflowY="scroll">
        <Thead>
          <Tr>
            <Th>Symptom Name</Th>
            <Th>Timestamp</Th>
            <Th>Notes (Optional)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {occurrences.isLoading ? (
            <Tr>
              <Td>Loading</Td>
              <Td>Loading</Td>
              <Td>Loading</Td>
            </Tr>
          ) : occurrences.data.length === 0 ? (
            <Tr>
              <Td>No Data</Td>
              <Td>No Data</Td>
              <Td>No Data</Td>
            </Tr>
          ) : (
            occurrences.data.map((occ) => (
              <Tr key={occ.id}>
                <Td>
                  {occ.symptomName === USER_NOTE_SYMPTOM_ID
                    ? "User Note"
                    : occ.symptomName}
                </Td>
                <Td>{occ.createdAt.toISOString()}</Td>
                <Td>{occ.note ?? "No note"}</Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
