import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon, RepeatClockIcon } from "@chakra-ui/icons";
import { useSymptoms, useOccurrences, Database, Occurrence } from "./data";
import { SymptomPrompt } from "./SymptomPrompt";
import { useState } from "react";
import { USER_NOTE_SYMPTOM_ID } from "./constants";

interface RecordHeaderProps {
  isPromptingNewSymptom: boolean;
  setNewSystemPromptVisibility: (show: boolean) => void;

  isAllowRemoveLastOccurrence: boolean;
  removeLastOccurrence: () => void;
}

export const RecordHeader = (props: RecordHeaderProps) => {
  const {
    isPromptingNewSymptom,
    setNewSystemPromptVisibility,
    isAllowRemoveLastOccurrence,
    removeLastOccurrence,
  } = props;

  return (
    <>
      <Button
        variant={"solid"}
        colorScheme={"teal"}
        size={"sm"}
        mr={4}
        leftIcon={<AddIcon />}
        disabled={isPromptingNewSymptom}
        onClick={() => {
          setNewSystemPromptVisibility(true);
        }}
      >
        New Symptom
      </Button>
      <Button
        variant={"solid"}
        colorScheme={"yellow"}
        size={"sm"}
        mr={4}
        leftIcon={<RepeatClockIcon />}
        disabled={isAllowRemoveLastOccurrence}
        onClick={() => {
          removeLastOccurrence();
        }}
      >
        Undo
      </Button>
    </>
  );
};

interface RecordViewProps extends RecordHeaderProps {
  database: Database | undefined;
  addOccurrance: (occurrence: Omit<Occurrence, "createdAt">) => void;
}

export const RecordView = (props: RecordViewProps) => {
  const {
    database,
    addOccurrance,
    isPromptingNewSymptom,
    setNewSystemPromptVisibility,
  } = props;
  const symptoms = useSymptoms(database);
  const occurrences = useOccurrences(database);
  const {
    isOpen: isNoteOpen,
    onOpen: onNoteOpen,
    onClose: onNoteClose,
  } = useDisclosure();
  const [noteText, setNoteText] = useState<string>("");

  return (
    <>
      <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={10}>
        {symptoms.data.map((symptom) => (
          <Button
            backgroundColor={symptom.color}
            key={symptom.name}
            onClick={() => {
              addOccurrance({
                symptomName: symptom.name,
              });
            }}
          >
            {symptom.name} (
            {
              occurrences.data.filter((o) => o.symptomName === symptom.name)
                .length
            }
            )
          </Button>
        ))}
        <Button
          backgroundColor="purple.600"
          color="whiteAlpha.900"
          onClick={onNoteOpen}
        >
          Add Note
        </Button>
      </SimpleGrid>
      <SymptomPrompt
        database={database}
        isVisible={isPromptingNewSymptom}
        onClose={() => {
          setNewSystemPromptVisibility(false);
        }}
      />
      <Modal isOpen={isNoteOpen} onClose={onNoteClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Note</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Your note contents"
              value={noteText}
              onChange={(e) => {
                setNoteText(e.currentTarget.value);
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                // first, store the note
                addOccurrance({
                  symptomName: USER_NOTE_SYMPTOM_ID,
                  note: noteText,
                });

                // then close the modal
                onNoteClose();
              }}
            >
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
