import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ThemeTypings,
} from "@chakra-ui/react";
import { useState } from "react";
import { Database, useSymptomMutation } from "./data";

type Color = ThemeTypings["colors"];

interface ColorSelectorProps {
  colors: Color[];
  onChange: (selected: Color) => void;
}

const ColorSelector = (props: ColorSelectorProps) => {
  const { colors, onChange } = props;
  const [activeColor, setActiveColor] = useState<Color>();

  return (
    <Flex direction="row" justify="space-evenly">
      {colors.map((color) => (
        <Box
          minW="40px"
          minH="40px"
          borderRadius="md"
          key={color?.toString() ?? "no-color"}
          backgroundColor={color}
          outline={`2px solid ${
            activeColor === color ? "#63b3ed" : "transparent"
          }`}
          boxShadow={`0 0 0 ${activeColor === color ? "1px" : "0"} #63b3ed`}
          onClick={(e) => {
            setActiveColor(color);
            onChange(color);
          }}
        />
      ))}
    </Flex>
  );
};

export interface SymptomPromptProps {
  database: Database | undefined;
  isVisible: boolean;
  onClose: () => void;
}

export const SymptomPrompt = (props: SymptomPromptProps) => {
  const { database, isVisible, onClose } = props;

  const [symptomName, setSymptomName] = useState<string>("");
  const [symptomColor, setSymptomColor] = useState<string>("whiteAlpha.200");
  const symptomMutation = useSymptomMutation(database);

  return (
    <Modal isOpen={isVisible} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add a Symptom</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={symptomMutation.isError}>
            <FormLabel>Symptom Name</FormLabel>
            <Input
              type="text"
              placeholder="Your Symptom"
              onChange={(e) => {
                setSymptomName(e.currentTarget.value);
              }}
            />
            <FormErrorMessage>
              {symptomMutation.error?.message ?? "Unknown Error"}
            </FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel>Symptom Color</FormLabel>
            <ColorSelector
              colors={[
                "whiteAlpha.200",
                "red.600",
                "green.600",
                "blue.600",
                "pink.600",
              ]}
              onChange={(color) => {
                setSymptomColor(color ?? "whiteAlpha.200");
              }}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => {
              symptomMutation.mutate(
                {
                  name: symptomName,
                  color: symptomColor,
                },
                {
                  onSuccess: onClose,
                }
              );
            }}
          >
            Add
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
