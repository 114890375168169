import { ChakraProvider, Link } from "@chakra-ui/react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link as RouterLink,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RecordHeader, RecordView } from "./RecordView";
import {
  ReportView,
  ReportDownloadButton,
  ReportClearButton,
} from "./ReportView";
import { PageLayout } from "./PageLayout";
import { useState } from "react";
import {
  Database,
  useDatabase,
  useOccurrenceAddMutation,
  useOccurrenceRemoveLastMutation,
} from "./data";

const queryClient = new QueryClient();

interface RecordPageLayoutProps {
  database: Database | undefined;
}

const RecordPageLayout = (props: RecordPageLayoutProps) => {
  const { database } = props;

  // a mutation for adding new occurrences
  const occurrenceAddMutation = useOccurrenceAddMutation(database);

  // a mutation for removing the last occurrence
  const occurrenceRemoveLastMutation =
    useOccurrenceRemoveLastMutation(database);

  // indicates if the prompt for a new symptom is showing
  const [isPromptingNewSymptom, setNewSystemPromptVisibility] =
    useState<boolean>(false);

  const headerProps = {
    isPromptingNewSymptom,
    setNewSystemPromptVisibility,
    isAllowRemoveLastOccurrence: !occurrenceAddMutation.isSuccess,
    removeLastOccurrence: () => {
      occurrenceRemoveLastMutation.mutate(undefined, {
        onSuccess: () => occurrenceAddMutation.reset(),
      });
    },
  };

  return (
    <PageLayout
      menu={
        <Link as={RouterLink} to="/reporting">
          Reporting
        </Link>
      }
      header={<RecordHeader {...headerProps} />}
    >
      <RecordView
        database={database}
        addOccurrance={(occ) => occurrenceAddMutation.mutate(occ)}
        {...headerProps}
      />
    </PageLayout>
  );
};

function App() {
  // the database provider
  const database = useDatabase();

  return (
    <ChakraProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<RecordPageLayout database={database} />}
            />
            <Route
              path="/reporting"
              element={
                <PageLayout
                  menu={
                    <Link as={RouterLink} to="/">
                      Recording
                    </Link>
                  }
                  header={
                    <>
                      <ReportDownloadButton database={database} />{" "}
                      <ReportClearButton database={database} />
                    </>
                  }
                >
                  <ReportView database={database} />
                </PageLayout>
              }
            />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;
