import { PropsWithChildren, ReactElement } from "react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Stack,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { LogoIcon } from "./LogoIcon";

interface Props {
  // optional menu content to add (e.g a link to another page)
  menu?: ReactElement;
  // optional header content to add (e.g. view-specific buttons)
  header?: ReactElement;
}

export const PageLayout = (props: PropsWithChildren<Props>) => {
  const { menu: menuContent, header: headerContent, children } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { toggleColorMode } = useColorMode();

  return (
    <>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"}>
            <Box>
              <LogoIcon />
            </Box>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              {menuContent}
              <Button onClick={() => toggleColorMode()}>
                Toggle Color Mode
              </Button>
            </HStack>
          </HStack>
          <Flex alignItems={"center"}>{headerContent}</Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {menuContent}
              <Button onClick={() => toggleColorMode()}>
                Toggle Color Mode
              </Button>
            </Stack>
          </Box>
        ) : null}
      </Box>
      <Box p={4}>{children}</Box>
    </>
  );
};
